import React from "react";
import "./style.css";
import Typewriter from "typewriter-effect";
import { introdata } from "../../content_option";

export const Home = () => {
  return (
    <div className="intro_sec d-block d-lg-flex align-items-center ">
      <div className="h_bg-image order-1 order-lg-2 h-100 d-flex align-items-center justify-content-center">
        <img src={introdata.your_img_url} alt="avatar" class="image-profile"/>
      </div>
      <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
        <div className="align-self-center ">
          <div className="intro mx-auto">
            <h2 className="mb-1x">{introdata.title}</h2>
            <h1 className="fluidz-48 mb-1x">
              <Typewriter
                options={{
                  strings: introdata.animated,
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 10,
                }}
              />
            </h1>
            <p className="mb-1x">{introdata.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
